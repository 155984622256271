.App {
  text-align: center;
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Sequel Sans";
  src: local("Sequel Sans"),
    url("./assets/fonts/Sequel\ Sans\ Medium\ Disp.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sequel Sans light";
  src: local("Sequel Sans"),
    url("./assets/fonts/Sequel\ Sans\ Light\ Body.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.circle {
  border: 1px solid white;
  border-radius: 50%;
  height: 52.7px;
  width: 52.7px;
  text-align: center;
}

.noInterligne {
  margin: 0px;
}

.Info1 {
  transform: translateY(2.4em);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-content {
  padding-top: 7em; /* Décale le contenu pour éviter qu'il passe sous le header */
}

.mantine-Grid-root {
  display: flex !important;
  flex-wrap: nowrap !important;
}
.mantine-Grid-col {
  flex: 1 !important;
  max-width: 50% !important;
}
